<template>
  <v-card>
    <v-card-title class="px-2">
      <Comeback /> {{ $t('reportInvoiceSammary') }} <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="exportLoading"
        class="d-none d-md-block"
        :loading="exportLoading"
        @click="exportExcel"
      >
        {{ $t('excel') }}
      </v-btn>
      <v-btn
        color="primary"
        class="d-block d-md-none"
        :loading="exportLoading"
        :disabled="exportLoading"
        icon
        fab
        outlined
        @click="exportExcel"
      >
        <v-icon>{{ mdiFileExcelOutline }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="px-2">
      <v-col cols="12" md="6" lg="3" class="py-0">
        <v-select
          v-model="userSelected"
          :items="userList"
          outlined
          dense
          :label="$t('staff_team')"
          item-text="teams_name"
          item-value="teams_id"
          @change="addPayload()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="py-0">
        <v-select
          v-model.trim="vatSelected"
          :items="vatList"
          :label="$t('tax_type')"
          dense
          outlined
          :item-text="$i18n.locale"
          item-value="value"
          @change="addPayload()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="py-0">
        <v-select
          v-model.trim="chanelPaySelected"
          :items="chanelPayList"
          :label="$t('channel_pay')"
          outlined
          dense
          item-text="shop_bank_name"
          item-value="shop_bank_id"
          @change="addPayload()"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.order_id`]="{ item }">
        <router-link
          v-if="item.order_id_pri"
          :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
          class="font-weight-medium text-decoration-none"
          target="_blank"
        >
          {{ item.order_id }}
        </router-link>
      </template>
      <template v-slot:[`item.detail`]="{ item }">
        {{
          item.detail && `${item.detail}`.length > 80 ? `${item.detail}`.substring(0, 80) + '...' : item.detail || ''
        }}
      </template>
      <template v-slot:[`item.order_create`]="{ item }">
        {{ item.order_create | sumdatetime }}
      </template>
      <template v-slot:[`item.order_total`]="{ item }">
        {{ item.order_total | formatPrice }}
      </template>
      <template v-slot:[`item.order_deposit_amount`]="{ item }">
        {{ item.order_deposit_amount | formatPrice }}
      </template>
      <template v-slot:[`item.orderpay_balance`]="{ item }">
        {{ item.orderpay_balance | formatPrice }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiCalendarBlank, mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportinvoice, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate, formatPrice, sumdatetime } from '@/plugins/filters'
import teamReport from '@/api/teamReport'
import vatDataForSearch from '@/@fake-db/data/vatDataForSearch.json'
import paymentChannel from '@/api/systemSetting/paymentChannel'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  filters: {
    formatPrice,
    sumdatetime,
  },
  setup() {
    const chanelPaySelected = ref('')
    const chanelPayList = ref([])
    const vatSelected = ref('')
    const vatList = ref(vatDataForSearch.data)
    const userList = ref([])
    const userSelected = ref('')

    // basic report
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const options = ref({})
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('receipt_code'), value: 'order_id', width: 120 },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 120 },
      { text: i18n.t('customer'), value: 'customer_name', width: 220 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 120 },
      { text: i18n.t('date'), value: 'order_create', width: 170 },
      {
        text: i18n.t('net_amount'),
        value: 'order_total',
        width: 180,
        align: 'end',
      },
      { text: i18n.t('user_is_list'), value: 'user_fullname', width: 170 },

      { text: i18n.t('note'), value: 'order_comment', width: 180 },
    ])

    teamReport.teamReportList().then(res => {
      userList.value = res
      userList.value.unshift({
        teams_id: '',
        teams_name: i18n.t('all_staff'),
      })
    })

    paymentChannel
      .paymentChannelList({
        searchtext: '',
        shop_bank_check: '',
      })
      .then(res => {
        chanelPayList.value = res.data
        chanelPayList.value.unshift({
          shop_bank_id: '',
          shop_bank_name: i18n.t('all'),
        })
      })

    onMounted(() => {
      addPayload()
    })

    const addPayload = () => {
      payload.value = {
        teams_id: userSelected.value,
        order_tax_id: vatSelected.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportinvoice(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const mapData = data => {
      data = data.map(item => {
        item.customer_tags = `${item.customer_tags ? item.customer_tags : ''}`
        delete item.order_id_pri

        return item
      })

      return data
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const a = mapData(dataTableList.value)
      const fileName = `${i18n.t('reportInvoiceSammary')}.xlsx`
      const dataExport = await removeComma(JSON.parse(JSON.stringify(a)))
      const Heading = [
        [`${i18n.t('reportInvoiceSammary')}`],
        [
          '#',
          `${i18n.t('receipt_code')}`,
          `${i18n.t('customer_code')}`,
          `${i18n.t('customer')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('date')}`,
          `${i18n.t('net_amount')}`,
          `${i18n.t('user_is_list')}`,
          `${i18n.t('note')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'order_id',
          'customer_id',
          'customer_name',
          'customer_tel',
          'order_create',
          'order_total',
          'user_fullname',
          'order_comment',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }]
      const wscols = [
        { wch: 8 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('reportInvoiceSammary')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      payload,
      loading,
      exportLoading,
      options,
      dataTableList,
      columns,
      chanelPaySelected,
      chanelPayList,
      vatSelected,
      vatList,
      userList,
      userSelected,
      addPayload,
      exportExcel,
      mdiFileExcelOutline,
      icons: { mdiCalendarBlank },
    }
  },
}
</script>
